<template>
  <v-container
    fluid
    class="white-background main-create-page"
    :class="[customClass]"
  >
    <div class="p-5 pb-0">
      <v-row>
        <v-col cols="7" class="pt-0">
          <slot name="header-title"></slot>
        </v-col>
        <v-col cols="5" class="pt-0 text-right">
          <slot name="header-action"></slot>
        </v-col>
      </v-row>
    </div>
    <slot name="body"></slot>
    <slot name="barcode-setting"></slot>
  </v-container>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      default: null,
    },
  },
};
</script>
