<template>
  <v-container fluid>
    <v-card flat class="custom-grey-border remove-border-radius">
      <v-card-title class="headline grey lighten-4">
        <h3 class="font-weight-700 custom-headline color-custom-blue">
          Invoice Schedule
        </h3>
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <template v-if="invoiceType === 'recurring'">
          <v-row>
            <v-col md="6" class="custom-border-right">
              <p class="m-0 font-weight-700 font-size-16">
                How do you want to invoice?
              </p>
              <v-radio-group row mandatory v-model="invoiceSchedule.type">
                <v-tooltip top max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-radio
                      label="Per visit"
                      :value="1"
                      :disabled="pageLoading"
                      color="cyan"
                      v-bind="attrs"
                      v-on="on"
                    ></v-radio>
                  </template>
                  <template v-slot>
                    <p class="m-0 text-justify">
                      Invoices include all the billable work on completed visits
                      (e.g. $40 a Visit)
                    </p>
                  </template>
                </v-tooltip>
                <v-tooltip top max-width="200">
                  <template v-slot:activator="{ on, attrs }">
                    <v-radio
                      label="Fixed price"
                      :value="2"
                      :disabled="pageLoading"
                      color="cyan"
                      v-bind="attrs"
                      v-on="on"
                    ></v-radio>
                  </template>
                  <template v-slot>
                    <p class="m-0 text-justify">
                      Each invoice is for a set amount (e.g. $300 a month)
                    </p>
                  </template>
                </v-tooltip>
              </v-radio-group>
            </v-col>
            <v-col md="6">
              <p class="m-0 font-weight-700 font-size-16">
                When do you want to invoice?
              </p>
              <v-select
                :items="invoiceScheduleList"
                dense
                filled
                flat
                :disabled="pageLoading"
                item-color="cyan"
                item-text="value"
                item-value="id"
                label="Schedule Type"
                hide-details
                solo
                color="cyan"
                v-model="invoiceSchedule.duration"
                class="mt-4"
              >
              </v-select>
              <template v-if="invoiceSchedule.duration == 1">
                <p class="m-0 mt-6 font-weight-700 font-size-16">Invoices</p>
                <v-row>
                  <v-col md="4" class="custom-border-right py-2">
                    <v-list-item-content>
                      <v-list-item-title class="font-size-14 font-weight-500"
                        >First</v-list-item-title
                      >
                      <v-list-item-subtitle
                        class="font-size-12 font-weight-500"
                        >{{ formatDate(firstInvoice) }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-col>
                  <v-col md="4" class="custom-border-right py-2">
                    <v-list-item-content>
                      <v-list-item-title class="font-size-14 font-weight-500"
                        >Last</v-list-item-title
                      >
                      <v-list-item-subtitle
                        class="font-size-12 font-weight-500"
                        >{{ formatDate(lastInvoice) }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-col>
                  <v-col md="4" class="py-2">
                    <v-list-item-content>
                      <v-list-item-title class="font-size-14 font-weight-500"
                        >Total</v-list-item-title
                      >
                      <v-list-item-subtitle
                        class="font-size-12 font-weight-500"
                        >{{ totalInvoice }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col md="12">
              <v-checkbox
                label="Remind me to invoice when I close the job"
                :value="1"
                :disabled="pageLoading"
                hide-details
                class="mr-4 mt-0"
                color="cyan"
                v-model="invoiceSchedule.remind_me"
              ></v-checkbox>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { InvoiceScheduleEventBus } from "@/core/lib/invoice/invoice.schedule.lib";
import CommonMixin from "@/core/plugins/common-mixin";
import { QUERY } from "@/core/services/store/request.module";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "invoice-schedule",
  data() {
    return {
      timeoutLimit: 500,
      timeoutSecondLimit: 500,
      timeout: null,
      timeoutSecond: null,
      firstInvoice: null,
      lastInvoice: null,
      startDate: null,
      endDate: null,
      totalInvoice: 0,
      invoiceType: null,
      invoiceScheduleList: [
        { id: 1, value: "Monthly on the last date of the month" },
        { id: 2, value: "After each visit is completed" },
        { id: 3, value: "As needed - no reminders" },
        { id: 4, value: "Once when job is closed" },
      ],
      invoiceSchedule: {
        remind_me: 1,
        type: 0,
        duration: 0,
      },
    };
  },
  props: {
    pageLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    updateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    invoiceType(param) {
      if (param === "recurring") {
        this.invoiceSchedule.remind_me = 0;
        this.invoiceSchedule.type = 1;
        this.invoiceSchedule.duration = 1;
      } else {
        this.invoiceSchedule.remind_me = 1;
        this.invoiceSchedule.type = 0;
        this.invoiceSchedule.duration = 0;
      }
    },
    invoiceSchedule: {
      deep: true,
      immediate: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          InvoiceScheduleEventBus.$emit(
            "update:invoice-schedule",
            _this.invoiceSchedule
          );
        }, _this.timeoutLimit);
      },
    },
  },
  methods: {
    getRecurrence() {
      const _this = this;
      let formData = {
        type: _this.invoiceSchedule.duration,
        start_date: _this.startDate,
        end_date: _this.endDate,
      };
      _this.firstInvoice = null;
      _this.lastInvoice = null;
      _this.totalInvoice = 0;
      if (
        formData.type === 1 &&
        moment(formData.end_date).isAfter(formData.start_date)
      ) {
        _this.$store
          .dispatch(QUERY, {
            url: "invoice/reminder-recurrence",
            data: formData,
          })
          .then(({ data }) => {
            _this.firstInvoice = data.start_date;
            _this.lastInvoice = data.end_date;
            _this.totalInvoice = data.total;
          })
          .catch((error) => {
            _this.logError(error);
          });
      }
    },
  },
  beforeDestroy() {
    /*InvoiceScheduleEventBus.$off("update:schedule-tab");
    JobScheduleTeamEventBus.$off("get:schedule-team");*/
  },
  created() {
    const _this = this;
    InvoiceScheduleEventBus.$on("update:schedule-tab", (argument) => {
      _this.invoiceType = argument;
    });

    JobScheduleTeamEventBus.$on("get:schedule-team", (param) => {
      try {
        _this.startDate = param.start_date
          ? moment(param.start_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
        _this.endDate = param.end_date
          ? moment(param.end_date).format("YYYY-MM-DD")
          : moment(_this.startDate).format("YYYY-MM-DD");
        clearTimeout(_this.timeoutSecond);
        _this.timeoutSecond = setTimeout(function () {
          _this.getRecurrence();
        }, _this.timeoutSecondLimit);
      } catch (error) {
        _this.logError(error);
      }
    });
  },
};
</script>
