<template>
  <div class="job-schedule-team">
    <div class="schedule-tab-item-header custom-grey-border p-3">
      <JobScheduleTeamHeader></JobScheduleTeamHeader>
    </div>
    <div class="schedule-tab-item-body custom-grey-border p-3">
      <JobScheduleTeamBody></JobScheduleTeamBody>
    </div>
  </div>
</template>

<script>
import JobScheduleTeamHeader from "@/view/pages/job/partials/Job-Schedule-Team-Header.vue";
import JobScheduleTeamBody from "@/view/pages/job/partials/Job-Schedule-Team-Body.vue";

export default {
  name: "job-schedule-team",
  components: {
    JobScheduleTeamHeader,
    JobScheduleTeamBody,
  },
};
</script>
