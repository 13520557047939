var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"job-schedule-team-header d-flex"},[_c('h3',{staticClass:"m-0 text-uppercase font-weight-700 color-custom-blue"},[_c('v-layout',[_c('v-flex',[_vm._v(" Team "),_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"value":true,"hide-details":"","disabled":_vm.scheduleLater,"color":"cyan"},on:{"change":_vm.getAvailableTeam},model:{value:(_vm.all),callback:function ($$v) {_vm.all=$$v},expression:"all"}})],1)]}}])},[_c('span',[_vm._v("Get All Available Engineers")])])],1),_c('v-flex',[(_vm.availableTeam.length)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-bold-button white--text",attrs:{"color":"cyan","right":"","small":"","disabled":_vm.scheduleLater}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-group")]),_vm._v("Assign ")],1)]}}],null,false,3517344468)},[_c('v-list',{staticClass:"light-gray-background pb-0",attrs:{"min-width":"340"}},[_c('v-list-item-title',{staticClass:"text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"},[_vm._v(" Select Team ")]),_vm._l((_vm.availableTeam),function(team,index){return _c('v-list-item',{key:index,staticClass:"white-background"},[_c('v-checkbox',{staticClass:"mr-4 mt-0",attrs:{"label":team.display_name,"value":team.id,"hide-details":"","color":"cyan","multiple":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-list-item',[_c('v-list-item-avatar',[_c('v-img',{attrs:{"lazy-src":_vm.$defaultProfileImage,"src":_vm.$assetAPIURL(team.profile_logo)}})],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:"font-size-16 font-weight-500",domProps:{"innerHTML":_vm._s(team.display_name)}},'v-list-item-title',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"text-capitalize"},[(team.skills)?[_vm._v(_vm._s(team.skills.join(", ")))]:(team.designation)?[_vm._v(_vm._s(team.designation.value))]:[_c('em',[_vm._v("No Skills")])]],2)]),(_vm.lodash.isEmpty(team.designation) == false)?_c('v-list-item-subtitle',[_vm._v(_vm._s(team.designation.value))]):_vm._e()],1)],1)]},proxy:true}],null,true),model:{value:(_vm.assignedTeam),callback:function ($$v) {_vm.assignedTeam=$$v},expression:"assignedTeam"}})],1)})],2)],1):_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"color":"cyan","right":"","small":"","loading":_vm.teamLoading,"disabled":_vm.scheduleLater || _vm.teamLoading},on:{"click":_vm.getAvailableTeam}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-account-group")]),_vm._v("Get Available ")],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }